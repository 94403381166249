/* google font @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

/* layout */

:root{
    --primary: #0d9c4d;
    --error: #dc3545;
}

body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header{
  background: #fff;
  min-height: 50px;
  overflow: visible;
}
header .container{
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: flex-start; /* Changed from center to flex-start */
  justify-content: space-between;
}

header a {
  color: #333;
  text-decoration: none;
}

.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

.home {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.workout-details{
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.workout-details h4{
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.workout-details p{
  margin: 0;
  font-size: 0.9em;
  color: #333;
}

.workout-details span{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}


label, input {
  display: block;
}

input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.login_mechanisms {
  display: flex; /* Use flexbox */
  justify-content: space-between; /* Space elements evenly */
}

form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins";
}
.google_btn img {
  width: 50px; /* Set the width for the image */
  height: auto; /* Set the height for the image */
}

div.error {
  background: #ffefef;
  color: #ffefef;
  border-radius: 5px;
  border: 2px solid var(--error);
  color: var(--error);
  margin: 30px
}

input.error {
  border: 1px solid var(--error);
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

nav a {
  margin-right: 10px;
}

nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-family: "Poppins";
  margin-left: 10px;
}

nav a{
  margin-right: 10px;
}

form.signup, form.login{
  max-width: 400px;
  margin: 40px auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

form.historyBot{
  max-width: 400px;
  margin: 40px auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.google_btn {
  position: relative;
  display: inline-block;
  border: 2px solid #2d2d2d;
  border-radius: 5px;
}

.google_btn::before,
.google_btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%; /* Adjust the width of the pseudo-elements to increase the border size */
  border: 2px solid #2d2d2d;
  z-index: -1;
}

.google_btn::before {
  left: 0;
  border-right: none; /* Remove right border to prevent doubling */
}

.google_btn::after {
  right: 0;
  border-left: none; /* Remove left border to prevent doubling */
}

.google_btn img {
  display: block;
  width: 100%; /* Ensure the image fills the container */
  border-radius: 2px; /* Apply rounded corners if needed */
  width: 50px;
  height: auto;
}

.translucent{
  opacity: 0.5;
}

.historyBotConversation {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.historyBotConversation h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.historyConversation {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.historyConversation li {
  margin-bottom: 10px;
  font-family: "Poppins";
  border-bottom: 1px solid #ddd;
}

.userHistoryMessage {
  text-align: right;
  color: #747474;
}





.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.buttonContainer .sidebarButton {
  flex: 1;
  margin: 0px;
}

.mainDiseaseView {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}



.sidebar {
  background-color: #f5f5f5;
  height: 100%;

}

@media (max-width: 600px) {
  .sidebar {
    background-color: #f5f5f5;
    padding: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    left: 0;
  
  }
  
  .sidebar h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul a {
    display: block;
    color: #000;
    padding: 10px;
    text-decoration: none;
  }
  
  .sidebar ul a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .sidebarButton {
    width: 100%;
    background-color: var(--primary);
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: block;
    border: none; /* Remove the black border */
    font-weight: bold; /* Make the text thicker */
  }
  
  .sidebarButton:hover {
    background-color: darkgreen;
  }
  .question-panel {
      width: 100%;
      background: #fff;
      border-radius: 4px;
      margin: 20px auto;
      padding: 20px;
      position: relative;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      /* Other styles */
  }
}





.PannelsContainer {
  display: flex;
  justify-content: space-between;
  height: 350px;
  border-radius: 4px;
  margin: 20px;
  padding: 20px;
}

.Pannel {
  width: 30%;
  height: 85%;
  background: #fff;
  border-radius: 4px;
  border: 40px; /* Replace margin with padding */
  box-shadow: 0 0 10px rgba(0,0,0,0.1);

  font-weight: bold; /* Makes the text bold */
  text-align: center; /* Centers the text */
  font-size: 30px; /* Sets the text size to 20px */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .PannelsContainer {
    flex-direction: column;
  }

  .Pannel {
    width: 100%;
  }
}

.Pannel a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: inherit; /* Inherits the color from the parent */
}

.Pannel a:hover, .Pannel a:active {
  color: inherit; /* Inherits the color from the parent */
  background: inherit; /* Inherits the background color from the parent */
}

.Pannel:hover {
  background: #ddd; /* Changes the background color on hover */
  box-shadow: 0 0 20px rgba(0, 101, 30, 0.2);

}

.Pannel:active {
  background: #bbb; /* Changes the background color on click */
  box-shadow: 0 0 20px rgba(0, 101, 30, 0.2);

}

.dropdown-menu {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.option-label {
  display: block;
  padding: 10px 0;
  transition: background-color 0.3s ease;
}

.option-label:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.question {
  margin-bottom: 20px;
}

.option {
  padding: 0px 0;

}

.explanation {
  margin-top: 20px;
}

.submit-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 10px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Mouse pointer on hover */
  transition-duration: 0.4s; /* Transition effects */
  border-radius: 5px;
}

.submit-button:hover {
  background-color: #45a049; /* Darker green on hover */
}
.submit-button:active {
  background-color: #387738; /* Even darker green when clicked */
}


.nav-link {
  color: grey;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: rgb(67, 67, 67);
}

.nav-link:active {
  color: black;
}

.hamburger-menu {
  display: none;
  background-color: white; /* Change to the color you want */
  color: #4CAF50; /* Change to the color you want */
  border: none; /* Add this line */
  font-size: 24px; /* Add this line */


}

.hamburger-menu:hover {
  color: #45a049; /* Darker green on hover */
}
.hamburger-menu:active {
  color: #387738; /* Even darker green when clicked */
}


.nav-menu {
  display: flex;
}
.container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.hamburger-menu {
  position: absolute;
  right: 20px;
  z-index: 1;
}





@media screen and (max-width: 768px) {
  .hamburger-menu {
      display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-menu.open {
      display: flex;
  }

  .menu-items {
      display: none;
      flex-direction: column;
  }

  .menu-items.open {
      display: flex;
  }
}

@media screen and (min-width: 769px) {
  .hamburger-menu {
      display: none;
  }

  .nav-menu {
      display: flex;
  }

  .menu-items {
      display: flex; /* Add this line */
      width: 100%;
  }
}


.sidebar_toggle{
  background-color: none; /* Change to the color you want */
  color: #4CAF50; /* Change to the color you want */
  border: none; /* Add this line */
  font-size: 32px; /* Add this line */

}

.sidebar_toggle:hover {
  color: #45a049; /* Darker green on hover */
}
.sidebar_toggle:active {
  color: #387738; /* Even darker green when clicked */
}